@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
h1,h2,h3,h4,h5,h6,p,a,span {
  font-family: 'Roboto', sans-serif;
  font-weight:400;
}


 .rete-list:after {
  content: '';
  position: absolute;
  left: 50px;
  right: 50px;
  top: 0;
  bottom: 0;
 
  background: -webkit-linear-gradient(left, rgba(57,100,208,1) 0%, rgba(45,193,201,1) 100%);
  border-radius: 10px;
 
}

.presale-name {
  display: inline-block;
  color: #fff;
 
  background: -webkit-linear-gradient(left, rgba(57,100,208,1) 0%, rgba(45,193,201,1) 100%);
 

  border-radius: 15px 15px 0 0;

}

.ecosystem:after {
  content: '';
  position: absolute;
  left: 50px;
  right: 50px;
  top: 0;
  
  bottom: 0;
width:40%;
margin:auto;
  background: -webkit-linear-gradient(left, rgba(57,100,208,1) 0%, rgba(45,193,201,1) 100%);
  border-radius: 10px;
}


#first {

  background: -webkit-linear-gradient(left, rgba(57,100,208,1) 0%, rgba(45,193,201,1) 100%)!important;
  border-radius:50%;
  padding:20px;
 
}
#second {
  background: -webkit-linear-gradient(left, rgba(57,100,208,1) 0%, rgba(45,193,201,1) 100%)!important;
}

.team-list {
  padding: 0 45px;
box-shadow: 0 2px 16px rgb(0 0 0 / 10%);

}
.team-list ul li a {
  background: -webkit-linear-gradient(left, rgba(57,100,208,1) 0%, rgba(45,193,201,1) 100%);

  
}
.team-list:after {
  content: '';
  position: absolute;
  left: 50px;
  right: 50px;
  top: -18px;
  bottom: 0;
   background: -webkit-linear-gradient(left, rgba(57,100,208,1) 0%, rgba(45,193,201,1) 100%);
   border-radius: 15px;
}

.search_main button {
  display: flex;
  align-items: center;
  width: 170px;
  margin-left: -179px;

  line-height: 50px;
  height:40px;
  border-radius: 50px;
  padding: 0 35px;
  font-weight: 700;
  text-transform: uppercase;
  animation-delay: 2.4s;
}

.hero-right:after {
  content: '';
  position: absolute;
  left: 50px;
  right: 50px;
  top: 0;
  bottom: 0;

  background: -webkit-linear-gradient(left, rgba(57,100,208,1) 0%, rgba(45,193,201,1) 100%);

  border-radius: 10px;
  z-index: -1;
}
.featured-lists ul {
  position: relative;
  display: block;
  overflow: hidden;
 
  z-index: 9999;
}
 .featured-lists ul li {
  position: relative;
  display: block;
  padding: 40px 0 0;
  overflow: hidden;
  
}
.featured-lists ul li:before {
  content: '';
  position: absolute;
  left: 30.5%;
  right: 0;
  width: 27%;
  height: 2px;
  background: #3872CF;
  top: 99px;
}



.featured-lists ul:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #3872CF;
  width: 2px;
  margin: 0 auto;
}
.featured-lists ul li:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 90px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #3872CF;
}

 .featured-lists ul li .imgs-l {
  position: relative;
  display: block;
  margin: 0 0 0 70px;
  text-align: left;
}

.featured-list ul {
  position: relative;
  display: block;
  overflow: hidden;

  z-index: 9999;
}
 .featured-list ul li {
  position: relative;
  display: block;
  padding: 40px 0 0;
  overflow: hidden;

}
.featured-list ul li:before {
  content: '';
  position: absolute;
  left: 35.5%;
  right: 0;
  width: 26%;
  height: 2px;
  background: #3872CF;
  top: 99px;
}



.featured-list ul:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #3872CF;
  width: 2px;
  margin: 0 auto;
}
.featured-list ul li:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 90px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #3872CF;
}

 .featured-list ul li .imgs-l {
  position: relative;
  display: block;
  margin: 0 0 0 70px;
  text-align: left;
}


 .cart-list .lsits:after {
  content: '';
  position: absolute;
  left: -18px;
  top: 7px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #3297cc;
}
html {
  scroll-behavior: smooth;

}
.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 39px !important;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased
}
.slick-arrow {
  position: absolute;
  top: 50%;
  margin: -20px 0px 0px 0px;
  z-index: 10;
  font-size: 30px;
  width: 30px;
  height: 30px;
  padding: 10px;
  
  color: -webkit-linear-gradient(left, rgba(57,100,208,1) 0%, rgba(45,193,201,1) 100%) !important;
  border-radius:50%;
  padding:20px;
}





.ihTjxo {
  color: #B2B4D7!important;
 
}

.slick-prev:before, .slick-next:before {
  background: -webkit-linear-gradient(left, rgba(57,100,208,1) 0%, rgba(45,193,201,1) 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;

  /* background: -webkit-linear-gradient(left, rgba(57,100,208,1) 0%, rgba(45,193,201,1) 100%) !important; */
 
}
